import React from 'react';
import Helmet from 'react-helmet';

import { Layout, RegulationBoxes, StateRegulationLinks } from '../components/common';
import { Regulation } from '../interfaces';
import JusticeBook from '../images/icons/186325-justice/svg/book.svg';

const FederalRegulation: Pick<Regulation, 'allowed' | 'disallowed' | 'obligations'> = {
  allowed: [{ label: 'Request for a copy of any background check or credit report.', tags: ['tenant'] }],
  disallowed: [{ label: 'Discrimination based on race, age, gender, etc.', tags: ['landlord'] }],
  obligations: [
    { label: 'Provide a safe and liveable residence.', tags: ['landlord'] },
    { label: 'Supply a copy of any background or credit report if requested.', tags: ['landlord'] },
    { label: 'Notify the landlord when something is going wrong.', tags: ['tenant'] },
  ],
};

const LawsAndRegulationsPage = () => (
  <Layout>
    <Helmet>
      <title>Rent Laws Summarized</title>
      <meta
        name="description"
        content="Renting is a major financial decision. It's important to know what laws and regulations there are to protect yourself and rent smarter."
      />
    </Helmet>
    <section className="hero is-black">
      <div className="hero-body">
        <div className="container content">
          <div className="columns is-centered is-vcentered">
            <div className="column is-narrow">
              <figure className="image is-128x128 has-mv-0 is-centered">
                <img className="is-rounded" src={JusticeBook} />
              </figure>
            </div>
            <div className="column is-6">
              <h1 className="title is-1 has-text-centered-mobile has-text-left is-marginless is-capitalized">
                Rent Laws Summarized
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h2 className="title has-text-centered">Federal Rent Laws Summarized</h2>
        <RegulationBoxes regulation={FederalRegulation} />
      </div>
    </section>
    <section className="section has-background-black">
      <div className="container">
        <h2 className="title is-2 has-text-centered has-mb-1">Find laws by state</h2>
        <StateRegulationLinks />
      </div>
    </section>
  </Layout>
);

export default LawsAndRegulationsPage;
